/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next/dist';
import PropTypes, { object } from 'prop-types';
import axios from 'axios';

// Lib UI components

// Local UI components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import Hero from '../page-components/TrainingDetail/Hero/index';
import TableContent from '../page-components/TrainingDetail/TableContent';
import ProgramObjectives from '../page-components/TrainingDetail/ProgramObjectives';
import AccessConditions from '../shared/UIKit/AccessConditions';
// Helpers
import { ProgramTrainingDetailEndPoint } from '../shared/APIs';
// Style
import '../page-styles/TrainingDetail.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function TrainingDetail({ pageContext: { id } }) {
  /* ********************************** HOOKS ********************************* */

  // state
  const [programDetails, setProgramsDetails] = useState();
  const { language } = useI18next();

  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */
  const locale = language === undefined ? 'fr' : language;
  /* ********************************** HELPERS********************************* */
  async function getProgramDetails() {
    const { data } = await axios(ProgramTrainingDetailEndPoint(locale, id));
    setProgramsDetails(data?.data?.attributes);
  }
  useEffect(() => {
    getProgramDetails();
  }, []);

  /* ******************************** RENDERING ******************************* */
  if (programDetails === undefined) return null;
  return (
    <PageLayout>
      <Seo title="Training detail" />
      <Hero
        title={programDetails?.title}
        description={programDetails?.description}
      />
      <TableContent contentList={programDetails?.content} />
      <ProgramObjectives objectifList={programDetails?.objectifs} />
      <AccessConditions conditionsList={programDetails?.accessConditions} />
    </PageLayout>
  );
}
TrainingDetail.propTypes = {
  pageContext: PropTypes.objectOf(object.isRequired).isRequired,
  id: PropTypes.number.isRequired,
};
TrainingDetail.defaultProps = {};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["TrainingDetail", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default TrainingDetail;
