/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function AccessConditions({ conditionsList }) {
  /* ******************************** CONSTANTS ******************************* */

  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */

  // localization ...
  const { t } = useTranslation();
  const { title } = t('conditions', { returnObjects: true });

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */
  /**
   * Computes the perimeter
   */

  /* ***************************** RENDER HELPERS ***************************** */

  function RenderTitle() {
    return (
      <Row align="center" justify="center">
        <h1>{title}</h1>
      </Row>
    );
  }
  function RenderAllConditions() {
    return conditionsList.map(
      // eslint-disable-next-line no-shadow
      ({ id, title, criteriaValue, criteriaLabel, description }) => (
        <Col align="center" justify="center" key={id}>
          <div className="access-conditions-requirement-card">
            <h2>{title}</h2>
            <h4>{criteriaValue}</h4>
            <h5>{criteriaLabel}</h5>
            <p>{description}</p>
          </div>
        </Col>
      ),
    );
  }

  /* ******************************** RENDERING ******************************* */

  return (
    <Container fluid className="fluid-background">
      <div className="background" />
      <Container
        className="access-conditions"
        style={{ marginTop: '5rem', marginBottom: '12rem' }}
      >
        <RenderTitle />
        <Row style={{ marginTop: '4rem' }}>
          <RenderAllConditions />
        </Row>
      </Container>
    </Container>
  );
}

AccessConditions.propTypes = {
  conditionsList: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

AccessConditions.defaultProps = {};

export default AccessConditions;
