/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents
import { Row, Col } from 'react-grid-system';
import Image from '../../../shared/Image';

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function TableContentItem({ idView, Description, title }) {
  /* ********************************** HOOKS ********************************* */
  // state
  const [showContentItem, setShowContentItem] = useState(false);

  /* ******************************** RENDERING ******************************* */
  const splitted = Description.split('\n');

  return (
    <Row align="center" justify="start" className="table-content-item">
      <h3>{idView}</h3>
      <p>{title}</p>
      <Col offset={{ md: 2 }} align="end">
        <button
          type="button"
          onClick={() => setShowContentItem(!showContentItem)}
        >
          {showContentItem ? (
            <Image src="minus.png" />
          ) : (
            <Image src="plus.png" />
          )}
        </button>
      </Col>

      <ul className={`${showContentItem ? 'animate' : ''}`}>
        {splitted.map((name) => (
          <li className="table-content-item-list">{name}</li>
        ))}
      </ul>
    </Row>
  );
}

TableContentItem.propTypes = {
  idView: PropTypes.string.isRequired,
  Description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

TableContentItem.defaultProps = {};

export default TableContentItem;
