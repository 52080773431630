/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
import Image from '../../../shared/Image';
import TableContentItem from '../TableContentItem';
// Redux actions and selectors=

// Helpers & utils

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function TableContent({ contentList }) {
  /* ******************************** CONSTANTS ******************************* */

  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */

  // State
  const [showMore, setshowMore] = useState(false);
  // Other hooks like reux or localization ...
  const { t } = useTranslation();
  const {
    table: { titleSection, showbutton, hideButton },
  } = t('trainingDetails', { returnObjects: true });

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */
  /**
   * Computes the perimeter
   */

  /* ***************************** RENDER HELPERS ***************************** */

  function RenderTitle() {
    return <h1 className="table-content-title">{titleSection}</h1>;
  }
  // render Table infos:
  function RenderTableContent() {
    return showMore
      ? contentList?.map(({ id, title, description }, index) => (
          <TableContentItem
            key={id}
            title={title}
            idView={index + 1}
            Description={description}
          />
        ))
      : contentList
          ?.slice(0, 4)
          .map(({ id, title, description }, index) => (
            <TableContentItem
              key={id}
              title={title}
              idView={index + 1}
              Description={description}
            />
          ));
  }

  function RenderButton() {
    return (
      <Row align="center" justify="center" style={{ marginTop: '2rem' }}>
        <button
          type="button"
          className="table-content-btn"
          onClick={() => setshowMore(!showMore)}
        >
          {!showMore ? showbutton : hideButton}
          <div className={`${showMore ? 'table-content-btn-show_less' : ''}`}>
            <Image src="arrowBottom.jpg" />
          </div>
        </button>
      </Row>
    );
  }
  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="table-content" style={{ marginTop: '2rem' }}>
      <Row align="center" justify="center">
        <Col xxl={9} xl={9} lg={9}>
          <RenderTitle />
          <RenderTableContent />
          <RenderButton />
        </Col>
      </Row>
    </Container>
  );
}

TableContent.propTypes = {
  contentList: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

TableContent.defaultProps = {};

export default TableContent;
