/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// UI lib comoponents
import { Container, Row, Col, Visible } from 'react-grid-system';

// Local UI components
import Image from '../../../shared/Image/index';

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';
import '../../../shared/styles/global.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Hero({ title, description }) {
  /* ******************************** CONSTANTS ******************************* */

  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */

  // Localisation:
  const { t } = useTranslation();
  const {
    hero: { buttons },
  } = t('trainingDetails', { returnObjects: true });

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */
  /**
   * Computes the perimeter
   */

  /* ***************************** RENDER HELPERS ***************************** */
  function RenderButtonsSection() {
    const rendredBtns = buttons.map(
      ({ id, buttonText, navigationPath, btnClassName }) => (
        <button type="button" key={id} className={btnClassName}>
          <a href={navigationPath} target="_blank" rel="noreferrer">
            {buttonText}
          </a>
        </button>
      ),
    );
    return <div className="buttons-container">{rendredBtns}</div>;
  }

  function RenderTitleDescDetail() {
    return (
      <>
        <h1 className="training-description-title">{title}</h1>
        <p>{description}</p>
      </>
    );
  }
  function RenderTrainingDescSection() {
    return (
      <Row>
        <div className="training-description">
          <RenderTitleDescDetail />
          <RenderButtonsSection />
        </div>
      </Row>
    );
  }
  function RenderHeroImage() {
    return (
      <div className="image-detail-container">
        <Image src="herodetail-training-detail.jpg" alt="detai-training" />
      </div>
    );
  }

  /* ******************************** RENDERING ******************************* */

  return (
    <>
      <Visible xxl xl lg md>
        <Container className="herodetail">
          <Row justify="center" align="center">
            <Col
              xl={5}
              lg={6}
              md={6}
              align="center"
              justify="center"
              style={{ zIndex: '1' }}
            >
              <RenderTrainingDescSection />
            </Col>
            <Col xl={5} lg={6} md={6}>
              <RenderHeroImage />
            </Col>
          </Row>
        </Container>
      </Visible>
      <Visible sm xs>
        <div className="herodetail" fluid>
          <Row direction="column" align="center" justify="center">
            <Col sm={12} xs={12}>
              <RenderHeroImage />
            </Col>
            <Col sm={10} xs={10} align="center" justify="center">
              <RenderTrainingDescSection />
            </Col>
          </Row>
        </div>
      </Visible>
    </>
  );
}

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

Hero.defaultProps = {};

export default Hero;
