/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
import Image from '../../../shared/Image';

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';
// Assets
import trueicon from '../../../images/AboutUs/check.svg';
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function ProgramObjectives({ objectifList }) {
  /* ******************************** CONSTANTS ******************************* */

  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */
  //  localization
  const { t } = useTranslation();
  const {
    objectives: { title },
  } = t('trainingDetails', { returnObjects: true });

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */
  /**
   * Computes the perimeter
   */

  /* ***************************** RENDER HELPERS ***************************** */

  function RenderSectionTitle() {
    return <h1 className="program-objectives-title">{title}</h1>;
  }
  function RenderObjectivesList() {
    const objectivesItems = objectifList?.map(({ id, objectif }) => (
      <li className="about-containter-list-item objectif" key={id}>
        <img src={trueicon} alt="true" className="true-img-1" />
        {objectif}
      </li>
    ));
    return objectivesItems;
  }

  /* ******************************** RENDERING ******************************* */

  return (
    <Container
      className="program-objectives"
      style={{ marginTop: '3rem', marginBottom: '3rem' }}
    >
      <Row align="center" justify="center">
        <Col xxl={5} xl={5} lg={5} md={12} sm={12} xs={12}>
          <Image src="about.jpg" alt="About" />
        </Col>
        <Col xxl={5} xl={5} lg={5} md={12} sm={12} xs={12}>
          <RenderSectionTitle />
          <RenderObjectivesList />
        </Col>
      </Row>
    </Container>
  );
}

ProgramObjectives.propTypes = {
  objectifList: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

ProgramObjectives.defaultProps = {};

export default ProgramObjectives;
